//Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$primary-font:"Poppins", sans-serif;

//Primary Colors
$primary-color:#00C2CB;
$primary-color-hover:#12A6AC;
$secondary-color:#E87246;
$dark-grey:#484848;
$light-grey:#5E5E5E;
$black:#000000;
$white:#ffffff;
$bg-color:#F8F8F8;
$input-grey:#DDDDDD;


//Font Weights
$light: 300;
$regular: 400;
$medium:500;
$semi-bold: 600;
$bold: 700;
$extra-bold:800;

//Font Size
$primary-fs:14px;
$head1-fs:18px;


//Mixins
@mixin body-reset{
    padding: 0;
    margin: 0;
}

@mixin b-radius() {
    border-radius: 8px;
}

@mixin LS-InputStyle {
    border: 1px solid $input-grey;
    height: 54px;
    @include b-radius();
}
