@import '../assets/styles/Variables.scss';

.ls-input{
    margin-bottom: 17px;
    .form-control {
        background: url(../assets/img/ic-mail.svg) right 20px center no-repeat;
        padding-right: 51px;
        @include LS-InputStyle;
    }
}


